/*noinspection CssInvalidAtRule*/
@import-normalize;

html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    align-items: center;
    justify-content: center;
    margin: 0;
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted #222;
    margin-left: 22px;
}

.tooltip .tooltiptext {
    width: 100px;
    background-color: #222;
    color: #fff;
    opacity: 0.8;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -60px;
}

.tooltip .tooltiptext::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #222 transparent transparent transparent;
}

.orders-pagination {
    position: static !important;
    padding: 0 !important;

}

.orders-pagination > div {
    position: static;
}

.header-categories-scroll-wrapper::-webkit-scrollbar {
    display: none;
}

.header-categories-scroll-wrapper {
    height: 46px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    overflow: hidden;
}

.header-categories-scroll-items-container {
    height: 100% !important;
    overflow: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

@media screen and (max-width: 767px) {
    .header-categories-scroll-items-container {
        overflow-x: visible;
    }

    .header-categories-scroll-items-container::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .header-categories-scroll-items-container::-webkit-scrollbar {
        width: 0;
    }

    .header-categories-scroll-items-container::-webkit-scrollbar-thumb {
        border-radius: 1px;
    }
}
